import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const AboutBottomContainerStyle = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 6rem 0;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;
const AboutBottomLeftStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .wrapper {
    padding-left: calc(calc(100vw - 1070px) / 2);
    padding-right: 3rem;
    @media (max-width: 1200px) {
      padding-left: 15px;
    }
  }
  .lead {
    font-size: 3.6rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    margin-bottom: 2rem;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -6rem;
      top: 2.2rem;
      width: 4rem;
      height: 2px;
      background: ${(props) => props.theme.darkBlue};
    }
  }
  .content {
    color: ${(props) => props.theme.darkGray};
    line-height: 1.8;
  }
  @media (max-width: 992px) {
    margin-bottom: 3rem;
    text-align: center;
  }
`;
const AboutBottomRightStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const AboutBottom = () => {
  return (
    <AboutBottomContainerStyle>
      <AboutBottomLeftStyle>
        <div className="wrapper">
          <div className="lead">Gondos kezekben készülnek</div>
          <div className="content">
            A HORIZONT PS védjegy alatt álló profil rendszerek már 1992 óta
            vannak forgalomban. A több mint 20 éves ablak és ajtó
            profilrendszerek gyártása során a folyamatos visszajelzéseket is
            szem előtt tartó fejlesztéseknek köszönhetően egyre jobb és jobb
            lett termék, amely időtálló és kiváló szigetelő tulajdonságokkal
            rendelkezik.
          </div>
        </div>
      </AboutBottomLeftStyle>
      <AboutBottomRightStyle>
        <StaticImage src="../../images/about-bottom.png" alt="" />
      </AboutBottomRightStyle>
    </AboutBottomContainerStyle>
  );
};

export default AboutBottom;
