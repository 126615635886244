import React from "react";
import Slider from "react-slick";
import Ikon1 from "../../images/termek-garancia.svg";
import Ikon2 from "../../images/tanusitvany.svg";
import Ikon3 from "../../images/elegedettseg.svg";
import styled from "styled-components";

const IndexPageAboutCarouselItem = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 0;
  gap: 2rem;
  & p:first-of-type {
    font-weight: 900;
    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  & p:last-of-type {
    color: ${({ theme }) => theme.darkGray};
    line-height: 1.8;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const IndexPageAboutCarouselIcon = styled.div`
  background: ${({ theme, iconColor }) => theme[iconColor] || iconColor};
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: grid;
  place-items: center;
  min-width: 6rem;
  & svg {
    width: 3rem;
    height: 3rem;
  }
`;

const AboutCarousel = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className="container">
      <Slider {...sliderSettings}>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="#ff8135">
            <Ikon1 />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              Termék
              <br />
              garancia
            </p>
            <p>
              A nálunk használt anyagokra <strong>20 év</strong> gyártói
              garancia vonatkozik.
            </p>
          </div>
        </IndexPageAboutCarouselItem>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="#b3bcea">
            <Ikon2 />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              Tanúsítvány
              <br />
              &nbsp;
            </p>
            <p>
              A gyártási folyamat során követjük a magas minőségi gyártásra
              vonatkozó irányelveket.
            </p>
          </div>
        </IndexPageAboutCarouselItem>
        <IndexPageAboutCarouselItem>
          <IndexPageAboutCarouselIcon iconColor="orange">
            <Ikon3 />
          </IndexPageAboutCarouselIcon>
          <div>
            <p>
              100%
              <br />
              elégedettség
            </p>
            <p>
              Nem zártunk még úgy projektet, hogy azzal tulajdonosa ne lenne
              maximálisan megelégedve.
            </p>
          </div>
        </IndexPageAboutCarouselItem>
      </Slider>
    </div>
  );
};

export default AboutCarousel;
