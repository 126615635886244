import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const BCWrapper = styled.div`
  color: white;
  background: ${({ theme }) => theme.lightBlue};
  .wrapper {
    padding: 8rem 0;
    position: relative;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/bc-bg.png");
      background-size: cover;
      opacity: 0.2;
      z-index: -1;
    }
  }
  h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 900;
    font-size: 5rem;
    text-transform: uppercase;
  }
  @media (max-width: 992px) {
    h1 {
      font-size: 4rem;
    }
    .wrapper {
      padding: 4rem 0;
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 2.5rem;
    }
  }
`;

const BCSmallItems = styled.div`
  display: flex;
  gap: 2rem;
  span {
    margin-left: 2rem;
  }
  @media (max-width: 992px) {
    justify-content: center;
  }
`;

const BC = ({ title, sub }) => {
  return (
    <BCWrapper>
      <div className="container">
        <div className="wrapper">
          <h1>{title}</h1>
          <BCSmallItems>
            {sub.map((s, i, a) => (
              <div key={`v${i}`}>
                {s.href ? <Link to={s.href}>{s.text}</Link> : <p>{s.text}</p>}
                {a.length !== i + 1 && <span>|</span>}
              </div>
            ))}
          </BCSmallItems>
        </div>
      </div>
    </BCWrapper>
  );
};

export default BC;
