import * as React from "react";
import AboutText from "../components/About/AboutText.component";
import BC from "../components/General/BC.component";
import Layout from "../components/General/Layout.component";
import IndexPageAbout from "../components/Index/IndexPageAbout.component";
import IndexPageAboutCarousel from "../components/Index/IndexPageAboutCarousel.component";
import IndexPageCTA from "../components/Index/IndexPageCTA.component";
import AboutCarousel from "../components/About/AboutCarousel.component";
import { aboutPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";
import AboutBottom from "../components/About/AboutBottom.component";
import styled from "styled-components";

const CustomBGStyle = styled.div`
  background: url("/sketch.png");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
  @media (max-width: 992px) {
    background-size: cover;
  }
`;

const AboutPage = () => {
  return (
    <Layout>
      {genPageInfo(aboutPage)}
      <BC
        title="Bemutatkozás"
        sub={[{ text: "Kezdőlap", href: "/" }, { text: "Rólunk" }]}
      />
      <AboutText />
      <CustomBGStyle>
        <IndexPageAbout noButton noBG />
        <IndexPageAboutCarousel />
        <AboutCarousel />
      </CustomBGStyle>
      <IndexPageCTA />
      <AboutBottom />
    </Layout>
  );
};

export default AboutPage;
