import React from "react";
import styled from "styled-components";

const AboutTextStyle = styled.section`
  color: ${({ theme }) => theme.darkGray};
  padding: 6rem 0;
  text-align: center;
  line-height: 1.8;
  p:first-of-type {
    margin-bottom: 3rem;
    color: black;
    font-weight: 900;
  }
`;

const AboutText = () => {
  return (
    <div className="container">
      <AboutTextStyle>
        <p>
          Több évtizedes szakmai tapasztalattal rendelkezünk a nyílászárók
          gyártása és beépítése terén.
        </p>
        <p>
          Lakossági vevők és kivitelezők számára egyedi tervezést, gyártást és
          beépítést vállalunk, pontosan betartott határidőkkel még viszonteladói
          partnereink számára versenyképes árakat és időtálló minőségi
          termékeket és rugalmaskiszolgálást biztosítunk.
        </p>
      </AboutTextStyle>
    </div>
  );
};

export default AboutText;
